





























import { defineComponent } from '@vue/composition-api'
import { Picture } from '@/inc/types'

export default defineComponent({
  name: 'GPicture',
  props: {
    content: {
      type: Object as () => Picture,
      Default: {} as Picture,
    },
  },
})
