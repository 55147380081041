import { logger } from '@/inc/utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
export default function vapperClient(ctx: any): void {
  const { VUE_APP_BROWSERSLIST_ENV } = process.env
  logger.trace('vapperClient', VUE_APP_BROWSERSLIST_ENV, ctx)

  const initApp = (): void => {
    logger.trace('initApp')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const domReady = (): Promise<Event | void> =>
    new Promise(resolve => {
      if (
        document.readyState === 'complete' ||
        document.readyState !== 'loading'
      ) {
        resolve()
      } else {
        document.addEventListener('DOMContentLoaded', resolve)
      }
    })

  Promise.all([domReady()]).then(initApp)
}
