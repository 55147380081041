<template>
  <main>
    <router-view :key="$route.meta.key || $route.fullPath" />
  </main>
</template>

<script>
export default {
  name: 'VRoot',
}
</script>
