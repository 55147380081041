











































































import { defineComponent } from '@vue/composition-api'
import { Intro } from '@/inc/types'

export default defineComponent({
  name: 'GIntro',
  props: {
    content: {
      type: Object as () => Intro,
      default: {} as Intro,
    },
  },
})
