
































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ChromeHeader from '@/components/chrome/Header.vue'
import ChromeFooter from '@/components/chrome/Footer.vue'
import EnvSwitcher from '@/components/EnvSwitcher.vue'
import WebGL from '@/components/webgl/WebGL.vue'

import NotFound from '@/views/NotFound.vue'
import { StorageUtils } from '@/inc/plugins/storage'
import { gsap } from 'gsap'
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin'
import { SplitText } from '@/inc/vendor/gsap-extra/SplitText'
import lock from '@/inc/utils/lock'
import ready from '@/components/webgl/ready'
gsap.registerPlugin(ScrollToPlugin, SplitText)

export default Vue.extend({
  name: 'App',
  components: {
    ChromeHeader,
    ChromeFooter,
    EnvSwitcher,
    WebGL,
    NotFound,
  },
  data() {
    return {
      hasError: false,
      isSameRoute: false,
    }
  },
  computed: {
    ...mapGetters(['meta', 'chrome', 'template']),
    ...mapGetters('cinema', ['cinema']),
  },
  methods: {
    ...mapMutations({
      setResource: 'SET_RESOURCE',
      setPrevRoute: 'SET_PREV_ROUTE',
    }),
    ...mapActions(['fetchChrome']),

    leave(el, done) {
      ready.ready.then(webgl => {
        if (this.isSameRoute) {
          const tl = gsap.timeline({
            onComplete: () => {
              done()
            },
          })

          const profileItem = document.querySelectorAll('.profiles-item')

          const blogItem = document.querySelectorAll('.blog-list__item')

          if (profileItem) {
            tl.to(
              profileItem,
              {
                opacity: 0,
                duration: 0.3,
                stagger: 0.05,
                ease: 'power4.out',
              },
              0
            )
          }

          if (blogItem) {
            tl.to(
              blogItem,
              {
                opacity: 0,
                duration: 0.3,
                stagger: 0.05,
                ease: 'power4.out',
              },
              0
            )
          }

          return
        }

        const tl = gsap.timeline({
          onComplete: () => {
            done()
          },
        })

        lock.lock()
        tl.to(window, {
          scrollTo: 0,
          duration: 0.5,
          ease: 'power4.out',
        })

        tl.to(
          webgl.scene.wave.material.uniforms.frequency,
          {
            value: 5,
            duration: 2,
          },
          0
        )

        tl.to(
          webgl.scene.grid.material.uniforms.force,
          {
            value: 5,
            duration: 2,
            ease: 'power4.out',
          },
          0
        )

        const heroTitle = document.querySelector('.hero__title')

        if (heroTitle) {
          const heroTitleSplit = new SplitText(heroTitle, {
            type: 'lines, words',
          })

          gsap.set(heroTitleSplit.lines, { overflow: 'hidden' })

          tl.to(
            heroTitleSplit.words,
            {
              yPercent: 100,
              duration: 1,
              stagger: 0.1,
              ease: 'power4.inOut',
            },
            0
          )
        }

        const headline = document.querySelector('.hero__headline')

        if (headline) {
          const heroHeadlineSplit = new SplitText(headline, {
            type: 'lines, words',
          })

          gsap.set(heroHeadlineSplit.lines, { overflow: 'hidden' })

          tl.to(
            heroHeadlineSplit.words,
            {
              yPercent: 100,
              duration: 1,
              stagger: 0.1,
              ease: 'power4.inOut',
            },
            0
          )
        }

        const vh =
          el.querySelector('.profile') || el.querySelector('.default-template')
            ? '100vh'
            : '50vh'

        tl.to(
          'main',
          {
            y: vh,
            duration: 0.8,
            ease: 'power4.in',
          },
          0.1
        )

        const hero = document.querySelector('.hero')

        if (hero) {
          tl.to(
            hero,
            {
              y: '-50vh',
              duration: 0.8,
              ease: 'power4.in',
            },
            0.1
          )
        }
      })
    },

    enter(el, done) {
      ready.ready.then(webgl => {
        this.$el.classList.remove('first-load')

        if (this.isSameRoute) {
          const tl = gsap.timeline({
            onComplete: () => {
              done()
            },
          })

          const blogItem = document.querySelectorAll('.blog-list__item')
          const profileItem = document.querySelectorAll('.profiles-item')

          if (profileItem) {
            tl.from(
              profileItem,
              {
                opacity: 0,
                duration: 0.3,
                stagger: 0.05,
                ease: 'power4.out',
              },
              0
            )
          }

          if (blogItem) {
            tl.from(
              blogItem,
              {
                opacity: 0,
                duration: 0.3,
                stagger: 0.05,
                ease: 'power4.out',
              },
              0
            )
          }

          return
        }

        const tl = gsap.timeline({
          onComplete: () => {
            done()
          },
        })

        tl.to(
          webgl.scene.wave.material.uniforms.frequency,
          {
            value: 29,
            duration: 2,
          },
          0
        )

        tl.to(
          webgl.scene.grid.material.uniforms.force,
          {
            value: 0.55,
            duration: 1,
          },
          0
        )

        const heroTitle = document.querySelector('.hero__title')

        if (heroTitle) {
          const heroTitleSplit = new SplitText(heroTitle, {
            type: 'lines, words',
          })

          gsap.set(heroTitleSplit.lines, { overflow: 'hidden' })

          tl.from(
            heroTitleSplit.words,
            {
              yPercent: 100,
              duration: 1,
              stagger: 0.1,
              ease: 'power4.out',
            },
            0
          )
        }

        const headline = document.querySelector('.hero__headline')

        if (headline) {
          const heroHeadlineSplit = new SplitText(headline, {
            type: 'lines, words',
          })

          gsap.set(heroHeadlineSplit.lines, { overflow: 'hidden' })

          tl.from(
            heroHeadlineSplit.words,
            {
              yPercent: 100,
              duration: 1,
              stagger: 0.1,
              ease: 'power4.out',
            },
            0
          )
        }

        const vh = el.querySelector('.profile') ? '100vh' : '50vh'

        tl.from(
          'main',
          {
            y: vh,
            duration: 2,
            ease: 'power4.out',
          },
          0.1
        )

        const hero = document.querySelector('.hero')

        if (hero) {
          tl.from(
            hero,
            {
              y: '-50vh',
              duration: 2,
              ease: 'power4.out',
            },
            0.1
          )
        }

        tl.add(() => {
          lock.unlock()
        }, 1.6)
      })
    },
  },
  // Needed for SSR rendering
  // https://vapperjs.org/data-prefetching.html#data-prefetch
  // BUT does not work with composition-api + defineComponent
  needSerialize: true,
  mounted() {
    window.setTimeout(() => {
      this.$el.style.opacity = 1
    }, 200)
  },
  async created() {
    this.$logger.trace('[app:created]', this.$$type)

    if (!this.$isServer) {
      this.hasError =
        document.querySelector('[data-server-rendered].error') !== null
    }

    if (this.hasError) {
      return
    }

    // Pass option to root context…
    // More convenient because $options.$storage could be undefined
    this.$root.$storage = this.$root.$options.$storage as StorageUtils

    await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })
    // Get API response serialized (thanks to `needSerialize`)
    // Or from router (e.g.: SSR error, SPA Fallback)
    // Init store resource
    if (this.$isServer) {
      this.setResource(this.$root.$options.$resource)
    } else if (!this.$isServer && document.body.dataset.rendering === 'SPA') {
      this.setResource(this.$router.$resource)
    }
  },
  watch: {
    $route(to, from) {
      this.setPrevRoute('true')
      this.setResource(this.$router.$resource)
      console.log('$route', to, from)

      // Access the "from" and "to" route information here
      const fromRoute = from.name
      const toRoute = to.name

      this.isSameRoute =
        (fromRoute === 'team' && toRoute === 'team') ||
        (fromRoute === 'news-archive' && toRoute === 'news-archive')
    },
  },

  head() {
    return this.meta
  },
})
