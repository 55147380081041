







































































import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GWysiwyg',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const template = `<div class="wysiwyg">${props.content}</div>`
    const runtimeComponent = computed(() => ({
      template,
    }))

    return {
      runtimeComponent,
    }
  },
})
