


















































































import { computed, defineComponent, ref, onMounted } from '@vue/composition-api'
import axios from 'axios'

export default defineComponent({
  name: 'env-switcher',
  setup(props, ctx) {
    const { $cookie, $logger } = ctx.root
    const isOpen = ref(false)
    const current = ref('staging')
    const envs = ref({})
    const hasEnvs = computed(() => Object.keys(envs.value).length > 0)
    const onToggle = () => {
      isOpen.value = !isOpen.value
    }
    const onChange = () => {
      $cookie.set('epic_env', current)
      window.location.reload()
    }

    onMounted(async () => {
      try {
        await axios.get('/envs.json').then(res => {
          envs.value = res.data
          current.value = res.data.staging
            ? 'staging'
            : Object.keys(res.data)[0]

          if ($cookie.get('epic_env')) {
            current.value = $cookie.get('epic_env')
          } else {
            current.value = res.data.staging
              ? 'staging'
              : Object.keys(res.data)[0]
            $cookie.set('epic_env', current.value)
          }
        })
      } catch (error) {
        $logger.warn('No ENVS found')
      }
    })

    return {
      isActive: process.env.VUE_APP_RELEASE === 'staging',
      isOpen,
      current,
      envs,
      hasEnvs,
      onToggle,
      onChange,
    }
  },
})
