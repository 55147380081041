import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/inc/types'

interface BackToState {
  url: string
}

const state: BackToState = {
  url: '',
}

const getters: GetterTree<BackToState, RootState> = {
  url: state => state.url,
}

const mutations = {
  BACKTO_ADD(state, payload) {
    state.url = payload
  },
  BACKTO_REMOVE(state) {
    state.url = null
  },
}

export const backTo = {
  state,
  getters,
  mutations,
  namespaced: true,
}
