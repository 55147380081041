















































































































































































import { defineComponent, ref, computed } from '@vue/composition-api'
import { gsap } from 'gsap'
import { Collapse } from '@/inc/types'

export default defineComponent({
  props: {
    content: {
      type: Object as () => Collapse,
      default: {} as Collapse,
    },
    index: {
      type: Number,
      default: 0,
    },
    isPress: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const pad = l => l.toString().padStart(2, '0')
    const isOpen = ref(false)
    const vBar = ref<null | HTMLElement>(null)
    const text = ref<null | HTMLElement>(null)
    const date = computed(() => {
      if (props.content.dateReview) {
        const d = new Date(props?.content?.dateReview)

        return `${pad(d.getMonth() + 1)}-${pad(
          d.getDate()
        )}<br>${d.getFullYear()}`
      }

      return ''
    })

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const textAnims = {
      enter(el, done) {
        el.style.height = '0'
        done()
      },
      afterEnter(el) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 'auto',
          ease: 'power4.inOut',
        })

        tl.to(
          vBar && vBar.value,
          {
            duration: 0.3,
            opacity: 0,
            yPercent: 50,
          },
          '=>'
        )
      },
      leave(el, done) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 0,
          ease: 'power4.inOut',
          onComplete: done,
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 1,
            yPercent: 0,
          },
          '=>'
        )
      },
    }

    return {
      date,
      isOpen,
      text,
      textAnims,
      toggle,
      vBar,
    }
  },
})
