



























































































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  ComputedRef,
} from '@vue/composition-api'
import lottie, { AnimationItem } from 'lottie-web'
import { useGetters } from '@u3u/vue-hooks'
// Import footerContact from '@/components/chrome/footer/Contact.vue'
import footerMenu from '@/components/chrome/footer/Menu.vue'
import { Chrome } from '@/inc/types'

// Bodymovin
import dw from '@/assets/json/dw.json'

const bodymovins = [
  {
    data: dw,
    slug: 'dw',
    step: 200,
  },
]

const lotties = [] as AnimationItem[]

const loadLottie = (obj, root) => {
  if (!root) {
    return
  }

  const container = root.querySelector(
    `[data-slug="${obj.slug}"]`
  ) as SVGSVGElement

  const anim = lottie.loadAnimation({
    container,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: obj.data,
  }) as AnimationItem

  lotties.push(anim)
}

export default defineComponent({
  components: {
    // FooterContact,
    footerMenu,
  },
  setup() {
    const isMounted = ref<boolean>(false)
    const [bodymovin] = bodymovins

    const generateLayers = () => {
      loadLottie(bodymovin, document)
    }

    const destroyActiveLotties = () => {
      lotties.forEach(l => l.destroy())
    }

    const init = () => {
      generateLayers()
    }

    const destroy = () => {
      destroyActiveLotties()
    }

    onMounted(() => {
      init()

      isMounted.value = true
    })

    onUnmounted(() => {
      destroy()
    })

    return {
      chrome: useGetters(['chrome']).chrome.value as ComputedRef<Chrome>,
      isMounted,
    }
  },
})
