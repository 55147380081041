<template>
  <div class="error">
    <h1>Ouuuch!</h1>
    <h2>Something bad happened</h2>
    <pre class="error__box">
Code: {{ error.code }}
Message: {{ error.message }}</pre
    >
    <pre class="error__stack">{{ error.stack }}</pre>
  </div>
</template>

<style lang="scss" scoped>
.error {
  margin: 2rem;
  padding: 0 2rem 2rem;
  border: 1px dotted $c-dev-error;
}

.error__box,
.error__stack {
  margin: 1rem 0 0;
  padding: 2rem;
  border: 1px dashed $c-dev-error;
}

.error__box {
  color: $c-white;
  font-size: 1.2em;
  background-color: $c-dev-error;
}
</style>
<script>
export default {
  name: 'ErrorComponent',
  props: ['error'],
  head() {
    return {}
  },
  created() {
    this.$logger.error('[SSR Error]', this.$props.error)
  },
}
</script>
