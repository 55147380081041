var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"cta"},[_c('div',{staticClass:"cta__content"},[_c('div',{staticClass:"cta__content__text"},[_vm._v(_vm._s(_vm.content.text))]),_c('g-btn',{staticClass:"cta__content__btn",attrs:{"btn":{
        label: _vm.content.label,
        url: _vm.content.url,
        target: _vm.content.target,
        icon: {
          slug: 'ui-arrow-right',
        },
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }