import Vue from 'vue'
import Vuex, { Store, StoreOptions } from 'vuex'

import { RootState } from '@/inc/types'
// Modules
import { backTo } from './modules/backTo'
import { chrome } from './modules/chrome'
import { resource } from './modules/resource'
// End modules

Vue.use(Vuex)

export default function createStore(): Store<RootState> {
  const store: StoreOptions<RootState> = {
    state: {
      version: '1.0.0',
    },
    modules: {
      backTo,
      chrome,
      resource,
    },
  }

  return new Vuex.Store<RootState>(store)
}
