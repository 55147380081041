







































import { defineComponent } from '@vue/composition-api'
import { Author } from '@/inc/types'

export default defineComponent({
  name: 'GAuthor',
  props: {
    content: {
      type: Object as () => Author,
      default: {} as Author,
    },
  },
})
