














































import { defineComponent } from '@vue/composition-api'
import { TagItem } from '@/inc/types'

export default defineComponent({
  name: 'GTags',
  props: {
    content: {
      type: Array as () => TagItem[],
      default: () => [],
    },
  },
})
