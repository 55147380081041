









































































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { Social } from '@/inc/types'

export default defineComponent({
  name: 'g-social-network',
  props: {
    content: {
      type: Array as () => Social[],
      default: [],
    },
    modifier: {
      type: String,
      default: '',
    },
  },
  setup(props, ctx) {
    const onClick = social => {
      if (ctx.root.$router.$$type === 'client') {
        const obj = {
          event: 'social-follow',
          eventCategory: 'social',
          eventAction: 'follow',
          eventLabel: social.slug,
        }

        console.log(obj)
        // Window.dataLayer.push(obj)
      }
    }

    return {
      ...useGetters(['chrome']),
      onClick,
    }
  },
})
