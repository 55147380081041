
















































































































































































import { defineComponent, ref, ComputedRef } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { Chrome } from '@/inc/types'

export default defineComponent({
  name: 'footer-menu',

  setup() {
    const isOpen = ref<boolean>(false)
    const toggleMenu = () => {
      isOpen.value = !isOpen.value
      document.body.classList.toggle('no-scroll')
    }

    return {
      chrome: useGetters(['chrome']).chrome.value as ComputedRef<Chrome>,
      ...useGetters(['currentLang']),
      isOpen,
      toggleMenu,
    }
  },
})
