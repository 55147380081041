





















import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { GenericObject } from '@/inc/types'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const player = ref<GenericObject | null>(null)
    const iframe = ref<HTMLDivElement | null>(null)
    const cinemaId = ref<string | null>(props.id)

    onMounted(() => {
      const body = document.querySelector('body')
      body && body.classList.add('is-locked')
    })

    const clearId = () => {
      cinemaId.value = null
      ctx.emit('clearId')

      const body = document.querySelector('body')
      body && body.classList.remove('is-locked')
    }

    return {
      clearId,
      cinemaId,
      iframe,
      player,
    }
  },
})
