























import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'

import ready from './ready'

export default defineComponent({
  name: 'WebGL',
  setup() {
    const canvas = ref<HTMLElement | null>(null)
    let instance

    onMounted(async () => {
      const mainWebGL = await import(
        /* webpackChunkName: 'main-webgl' */ './main'
      ).then(module => module.default)

      instance = new mainWebGL()

      if (canvas.value) {
        canvas.value.appendChild(instance.renderer.domElement)
      }

      ready.ready.resolve(instance)
    })

    onUnmounted(() => {
      console.log(instance)
      // Destroy instance
    })

    return {
      canvas,
    }
  },
})
