





































































import { defineComponent } from '@vue/composition-api'
import { Duo } from '@/inc/types'

export default defineComponent({
  name: 'GDuo',
  props: {
    content: {
      type: Object as () => Duo,
      default: {} as Duo,
    },
  },
})
