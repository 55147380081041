


























































































































import { defineComponent } from '@vue/composition-api'
import { Link } from '@/inc/types'

export default defineComponent({
  name: 'GLink',
  props: {
    content: {
      type: Object as () => Link,
      default: {} as Link,
    },
    tag: {
      type: String,
      default: 'a',
    },
  },
})
