<template>
  <div class="date">
    <div v-if="date" class="date__label">
      {{ date }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.date__label {
  @extend %fw-bold;

  margin-top: $spacing * 1.5;
  color: $c-gray-lighter;
  font-size: 1.4rem;
}
</style>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'GDate',
  props: {
    content: {
      // REVIEW: sure about Number? -> another default…
      // type: Number,
      // default: '',
      type: Number,
      default: Date.now(),
    },
  },
  setup(props) {
    const pad = l => l.toString().padStart(2, '0')
    const date = computed(() => {
      if (props.content) {
        const d = new Date(props?.content)
        console.log('d', d)

        return isNaN(d.getTime())
          ? ''
          : `${pad(d.getDate())}-${pad(d.getMonth() + 1)}-${d.getFullYear()}`
      }

      return ''
    })

    return {
      date,
    }
  },
})
</script>
