




































































































































































































































































































import { defineComponent, ref, ComputedRef } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { gsap } from 'gsap'
import { Chrome } from '@/inc/types'

export default defineComponent({
  name: 'ChromeNav',
  setup() {
    const isSubMenuOpen = ref(false)

    const onMenuClick = () => {
      isSubMenuOpen.value = false
    }

    const toggleMenu = () => {
      if (isSubMenuOpen.value) {
        closeSub()
      } else {
        openSub()
      }
    }

    const openSub = () => {
      isSubMenuOpen.value = true
    }

    const closeSub = () => {
      isSubMenuOpen.value = false
    }

    const subMenu = {
      enter(el, done) {
        el.style.opacity = '0'
        done()
      },
      afterEnter(el) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          opacity: 1,
          ease: 'power4.out',
        })
      },
      leave(el, done) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          opacity: 0,
          ease: 'power4.inOut',
          onComplete: done,
        })
      },
    }

    return {
      isSubMenuOpen,
      onMenuClick,
      subMenu,
      toggleMenu,
      chrome: useGetters(['chrome']).chrome.value as ComputedRef<Chrome>,
    }
  },
})
