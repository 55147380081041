






























































import { defineComponent } from '@vue/composition-api'
import { Collapses } from '@/inc/types'

export default defineComponent({
  name: 'GCollapses',
  props: {
    isPress: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object as () => Collapses,
      default: {} as Collapses,
    },
  },
})
