



















































































































































































































































































import { defineComponent, computed } from '@vue/composition-api'
import { CardItem } from '@/inc/types'

export default defineComponent({
  name: 'GCard',
  props: {
    content: {
      type: Object as () => CardItem,
      default: {} as CardItem,
    },
    target: {
      type: String,
      default: '_self',
    },
  },

  setup(props) {
    const pad = l => l.toString().padStart(2, '0')
    const date = computed(() => {
      if (props.content.date) {
        const d = new Date(props?.content?.date)

        return `${pad(d.getDate())}-${pad(d.getMonth() + 1)}-${d.getFullYear()}`
      }

      return ''
    })

    return {
      date,
    }
  },
})
