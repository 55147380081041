








































































































































import { defineComponent } from '@vue/composition-api'
import { Btn } from '@/inc/types'

export default defineComponent({
  name: 'GBtn',
  props: {
    btn: {
      type: Object as () => Btn,
      default: () => ({} as Btn),
    },
    content: {
      type: Object as () => Btn,
      default: () => ({} as Btn),
    },
    tag: {
      type: String,
      default: 'router-link',
    },
  },
})
