import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { Languages, Resource, ResourceState, RootState } from '@/inc/types'

const state: ResourceState = {
  content: {},
  languages: {} as Languages,
  meta: {},
  template: '',
}

const getters: GetterTree<ResourceState, RootState> = {
  content: state => state.content,
  languages: state => state.languages,
  meta: state => state.meta,
  template: state => state.template,
}

const mutations: MutationTree<ResourceState> = {
  SET_RESOURCE(state, payload: Resource) {
    if (payload) {
      const { content, languages, meta, template } = payload

      state.content = content
      state.languages = languages
      state.meta = meta
      state.template = template
    }
  },
}

const actions: ActionTree<ResourceState, RootState> = {}

export const resource: Module<ResourceState, RootState> = {
  state,
  getters,
  mutations,
  actions,
}
