import VueRouter from 'vue-router'

import { langAvailable, langDefault } from '@/inc/app.config'
import { loadLanguageAsync, setI18nLanguage } from '@/inc/i18n'
import { fetch, logger } from '@/inc/utils'
import { Language, Resource } from '@/inc/types'

export const guards = (router: VueRouter) => {
  // Forced SSR error -> SPA rendering
  // router.beforeEach((to, from, next) => {
  //   if (router.$$type === 'server') {
  //     try {
  //       throw Error('Forced SSR error')
  //     } catch (e) {
  //       next(e)
  //     }
  //   } else {
  //     next()
  //   }
  // })

  // Log
  router.beforeEach((to, from, next) => {
    logger.trace('[guards] from', from.name, 'to', to.name)
    next()
  })

  // Manage languages
  if (langAvailable.length > 1) {
    router.beforeEach(async (to, from, next) => {
      const { lang: langCurrent } = from.params as { lang: Language }
      const lang = to.params ? (to.params.lang as Language) : langDefault

      const langHasChanged = langCurrent !== undefined && langCurrent !== lang
      const langNext = langAvailable.includes(lang) ? lang : langDefault

      await loadLanguageAsync(langNext)
        .then(langLoaded => setI18nLanguage(langLoaded, router.$$type))
        .then(lang => {
          if (langHasChanged) {
            return router.app.$store.dispatch('fetchChrome', { lang })
          }

          return Promise.resolve()
        })
        .catch(error => {
          logger.error(error)
        })

      next()
    })
  }

  // Fetch content
  router.beforeEach(async (to, from, next) => {
    const isSpaFallback =
      router.$$type === 'client' && document.body.dataset.rendering === 'SPA'
    // Check if fetch is needed:
    // - on SPA fallback
    // - on second rendering ($resource is defined), client side
    const needFetch =
      isSpaFallback ||
      (router.$resource !== undefined && router.$$type === 'client')

    if (!needFetch) {
      router.$resource = {} as Resource

      return next()
    }

    // Fetch page resource
    // resource is added through `router`
    try {
      await fetch(to, router, false)
    } catch (error) {
      console.error(error)
    }

    return next()
  })
}
