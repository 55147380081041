



























































import { ref, defineComponent } from '@vue/composition-api'
import { Hero } from '@/inc/types'

export default defineComponent({
  name: 'GHero',
  props: {
    content: {
      type: Object as () => Hero,
      default: {} as Hero,
    },
  },

  setup() {
    const hero = ref<HTMLElement | null>(null)
    const headline = ref<HTMLElement | null>(null)
    const title = ref<HTMLElement | null>(null)

    return {
      hero,
      headline,
      title,
    }
  },
})
