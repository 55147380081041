// Webpack imports
import '@/styles/main.scss'

import Vue from 'vue'
import axios from 'axios'

// Plugins
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)
import hooks from '@u3u/vue-hooks'
Vue.use(hooks)
import VueAware from 'vue-aware'
Vue.use(VueAware)
import VueSvgSprite from 'vue-svg-sprite'
Vue.use(VueSvgSprite, {
  url: '',
})
// Import VuePageTransition from 'vue-page-transition'
// Vue.use(VuePageTransition)

// Directives
import VueOutside from '@/inc/directives/outside'
Vue.directive('outside', VueOutside)
import VueSrc from '@/inc/directives/src'
Vue.directive('src', VueSrc)

// Filters
// import LineBreak from '@/inc/filters/linebreak'
// Vue.filter('linebreak', LineBreak)
// import Modifiers from '@/inc/filters/modifiers'
// Vue.filter('modifiers', Modifiers)
import VFilters, {
  VFiltersOptions,
  linebreak,
  modifiers,
} from '@monofront/v-filters'

Vue.use<VFiltersOptions>(VFilters, { linebreak, modifiers })

import i18n from '@/inc/i18n'
import { App, AppType } from '@/inc/types'
import { logger, keysToCamel } from '@/inc/utils'

Vue.prototype.$logger = logger

logger.info(process.env)
Vue.config.productionTip = false

// Import all global components
// Btn.vue => <GBtn></GBtn>
const requireComponent = require.context('@/components/g', true, /.*\.vue$/)

requireComponent.keys().forEach(fileName => {
  let baseComponentConfig = requireComponent(fileName)

  baseComponentConfig = baseComponentConfig.default || baseComponentConfig

  const baseComponentName =
    baseComponentConfig.name ||
    `G${fileName.replace(/^.+\//, '').replace(/\.\w+$/, '')}`

  Vue.component(baseComponentName, baseComponentConfig)
})

// Export factory function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createApp(ctx: any): App {
  // Test commit / lint-staged
  const { type } = ctx as { type: AppType }

  logger.trace('[main:createApp]', type)

  // Send cookies with request
  axios.defaults.withCredentials = true
  // Transform properties casing
  axios.interceptors.response.use(response => {
    if (response?.data) {
      response.data = keysToCamel(response.data)
    }

    return response
  })

  if (type === 'client') {
    axios.defaults.baseURL = window.location.origin
  }

  /* eslint-disable @typescript-eslint/no-var-requires, global-require */
  const AppComponent = require('@/App.vue').default
  const ErrorComponent = require('@/Error.vue').default
  const createRouter = require('@/router').default
  const createStore = require('@/store').default
  /* eslint-enable @typescript-eslint/no-var-requires, global-require */

  const store = createStore()
  const router = createRouter()
  const app = {
    ErrorComponent,
    i18n,
    router,
    store,
    // This is necessary, it is for vue-meta
    head: {
      titleTemplate: '%s',
    },
    // This will expose `$root.$options.$resource` into components (server-side)
    $resource: ctx.$resource,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (h: any) => h(AppComponent),
  }

  return app
}
