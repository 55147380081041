class Lock {
  constructor() {
    this.isLocked = false
    this.stop = this.stop.bind(this)
  }

  // eslint-disable-next-line class-methods-use-this
  stop(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  lock() {
    document.body.addEventListener('mousewheel', this.stop, {
      passive: false,
    })

    document.body.addEventListener('touchmove', this.stop, {
      passive: false,
    })

    document.body.addEventListener('keydown', this.stop, {
      passive: false,
    })
  }

  unlock() {
    document.body.removeEventListener('mousewheel', this.stop)
    document.body.removeEventListener('touchmove', this.stop)
    document.body.removeEventListener('keydown', this.stop)
  }
}

export default new Lock()
