







































import { defineComponent, ref } from '@vue/composition-api'
import Cinema from '@/components/chrome/Cinema.vue'
import { GenericObject } from '@/inc/types'

import gsap from 'gsap'

export default defineComponent({
  name: 'g-video',

  components: {
    Cinema,
  },

  setup() {
    const video = ref<null | HTMLElement>(null)
    const cinemaId = ref<string | null>(null)
    const player = ref<GenericObject | null>(null)
    const openCinema = id => {
      console.log('openCinema', id)
      cinemaId.value = id
    }

    const clearId = () => {
      console.log('clearId')
      cinemaId.value = null
    }

    const anims = {
      beforeEnter(el) {
        gsap.set(el, { opacity: 0 })
      },
      enter(el, done) {
        gsap.to(el, 0.5, {
          opacity: 1,
          ease: 'power4.out',
          onComplete: () => {
            done()
          },
        })
      },
      leave(el, done) {
        gsap.to(el, 1, {
          opacity: 0,
          ease: 'power4.inOut',
          onComplete: () => {
            player.value && player.value.destroy()
            done()
          },
        })
      },
    }

    return {
      clearId,
      openCinema,
      video,
      cinemaId,
      anims,
    }
  },

  props: {
    content: Object,
  },
})
