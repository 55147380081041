





























import { defineComponent } from '@vue/composition-api'
import { TagItem } from '@/inc/types'

export default defineComponent({
  name: 'GTag',
  props: {
    content: {
      type: Object as () => TagItem,
      default: {} as TagItem,
    },
  },
})
