
















































import { defineComponent, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { parseRoute } from '@/inc/utils'
import gsap from 'gsap'
import ready from '@/components/webgl/ready'

export default defineComponent({
  name: 'VNotFound',
  setup(props, ctx) {
    const { api, endpoint, resource } = parseRoute(ctx.root.$route)

    const onLoad = () => {
      ready.ready.then(webgl => {
        const tl = gsap.timeline()

        tl.to(
          webgl.scene.wave.material.uniforms.uColor.value,
          {
            r: 236 / 255,
            g: 250 / 255,
            b: 255 / 255,
            duration: 1,
            ease: 'power4.out',
          },
          0
        )
      })
    }

    onMounted(() => {
      console.log('Not found', ctx, props)
      onLoad()
    })

    return {
      ...useGetters(['currentLang']),
      url: `${api}/${endpoint}/${resource}`,
    }
  },
})
